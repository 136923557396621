<template>
  <OverlayContainer
    @close-overlay="closeLanguageSwitcher"
    :class="$style['language-switcher-overlay']"
  >
    <div :class="$style['language-switcher-overlay__wrapper']">
      <div :class="$style['language-switcher-overlay__image_wrapper']">
        <img
          :class="$style['language-switcher-overlay__image']"
          src="../../assets/img/global/global__logo-shield.png"
        />
      </div>
      <Button
        v-for="locale in $i18n.availableLocales"
        @click.native="switchLocale(locale)"
        :key="locale"
        :class="$style['language-switcher-overlay__button']"
        :text="$i18n.messages[locale].languageName"
        :type="
          locale === $i18n.locale ? buttonTypes.FILLED : buttonTypes.OUTLINE
        "
      />
    </div>
  </OverlayContainer>
</template>

<script>
import OverlayContainer from '@/components/containers/OverlayContainer';

export default {
  name: 'LanguageSwitcherOverlay',
  components: {
    OverlayContainer
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale === locale) {
        return;
      }
      const params = { ...this.$route.params, locale };
      this.$i18n.locale = locale;
      this.$router.push({ name: this.$route.name, params });
      this.closeLanguageSwitcher();
      document.documentElement.lang = this.$i18n.locale;
    },
    closeLanguageSwitcher() {
      this.$store.commit('setLanguageSwitcherOpen', false);
    }
  }
};
</script>

<style module>
.language-switcher-overlay {
  @apply bg-black;
}

.language-switcher-overlay__wrapper {
  @apply flex flex-col items-center justify-center
  h-full w-3/5
  m-auto;
}

.language-switcher-overlay__image_wrapper {
  @apply mb-8;
}

.language-switcher-overlay__image {
  @apply max-h-full max-w-full;
}

.language-switcher-overlay__button {
  @apply w-full;
}

.language-switcher-overlay__button:not(:last-child) {
  @apply mb-4;
}
</style>
